import React from "react"
import HeaderComponent from "../components/templates/HeaderComponents/header"
import LoginComponent from "../components/LoginComponents/Login"
import LayoutProvider from "../../src/components/Layout"

const LoginPage = ({ location }) => {
  return (
    <LayoutProvider location={location}>
      <HeaderComponent siteTitle={"Login"} />

      <LoginComponent />
    </LayoutProvider>
  )
}
export default LoginPage